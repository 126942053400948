.gallery {
  height: 175svh;
  overflow: hidden;
  background: rgb(226, 226, 226);
  .galleryWrapper {
    position: relative;
    top: -12.5vh;
    height: 200svh;
    display: flex;
    gap: 2vw;
    padding: 2vw;
    .column {
      position: relative;
      height: 100%;
      width: 50%;
      min-width: 150px;
      display: flex;
      flex-direction: column;
      gap: 2vw;
      white-space: nowrap;
      will-change: transform;
      .imageContainer {
        position: absolute;
        height: 33%;
        width: 100%;
        position: relative;
        border-radius: 1vw;
        overflow: hidden;
        img {
          object-fit: cover;
        }
      }

      &:nth-of-type(1) {
        top: -45%;
      }
      &:nth-of-type(2) {
        top: -15%;
      }
    }
  }
}
