.main-wrapper {
  height: 300lvh;
  width: 100%;
}

.video-wrapper {
  height: 100lvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.count-wrapper {
  color: #a9abb5;
  display: flex;
  align-items: center;
  justify-self: center;
  height: 200lvh;
  width: 100%;
  flex-direction: column;
  position: relative;
  overflow: visible;

  .a80-wrapper {
    background-color: #151519;
    position: sticky;
    top: 0;
    height: 100lvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 48px 0;
    overflow: hidden;
  }

  .text-wrapper {
    position: relative;
    height: max-content;
    width: max-content;
  }

  .text-style {
    font-size: 44vw;
    position: relative;
    line-height: 1;
  }

  .eighty_mb {
    display: block;
  }

  .pl {
    padding: 6px 18px;
    border-radius: 40px;
    border: 1px solid darkgray;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
    position: absolute;
    right: -10px;
    top: 15%;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .text-label {
    font-size: 26px;
    text-align: center;
    line-height: 1;
  }
}

.extra-content {
  text-align: center;

  span {
    font-size: 32px;
  }
}
